<template>
    <div class="businessOfScope">
        <div class="productMix">
            <!-- <div class="title">产品结构</div>
            <div class="product">
                <div class="product_one">
                    <span class="product_one_1">钢铁资讯</span>
                    <img src="../../assets/jiatou.png" alt="">
                    <span>钢铁新闻</span>
                    <span>各地价格</span>
                    <span>行情分析</span>
                    <span>钢厂调价</span>
                </div>
                <div class="product_one">
                    <span class="product_one_1">钢铁资讯</span>
                    <img src="../../assets/jiatou.png" alt="">
                    <span>钢铁新闻</span>
                    <span>各地价格</span>
                    <span>行情分析</span>
                    <span>钢厂调价</span>
                </div>
                <div class="product_one">
                    <span class="product_one_1">钢铁资讯</span>
                    <img src="../../assets/jiatou.png" alt="">
                    <span>钢铁新闻</span>
                    <span>各地价格</span>
                </div>
            </div> -->
            <div class="title">产品生态</div>
            <div class="images">
                <img src="../../assets/pic_shengtai.png" alt="">
            </div>
            <div class="title">自身优势共享</div>
            <div class="images">
                <img src="../../assets/pic_youshi.png" alt="">
            </div>
            <div class="title">智能化交易平台——超级“大卖场”</div>
            <div class="images2">
              <p>
                超级大卖场为商家量身定制搭建卖场服务，为卖家提供专区展示、宣传及交易店铺，为平台买家提供海量资源选择，上下游可自主交易。旨在帮供应多客户高效卖货，助采购商多比价降低成本。
              </p>
              <p> 
                平台卖货操作简单，快速上手，只需自助注册认证，便可开设店铺，挂牌资源、自主交易资源可实时更新，模板导入或在线编辑均可上传，更新每日货源便捷迅速；店铺PC、APP多端同步展示，平台资源分地域、分品种、分钢厂多维度推送客户群体；
              </p>
              <p>
              中钢网会将现货资源分发给50余万用户与粉丝，精准流量，让采购客户主动电话联系钢铁企业询价和买货。
              用户可一键上传现货资源，智能匹配采购订单。商家可以根据用户访问时长、浏览深度等因素，多维度智能筛选优质客户进行精准匹配，实时通过系统提醒“谁看过我的店铺、我去看过谁的订单”，保证客户不流失，帮供应商多客户高效卖货，助采购商多比价降低成本。
              </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'businessOfScope',
    data() {
        return {
            
        }
    },
}
</script>

<style lang="less" scoped>
    .businessOfScope {
        width: 63%;
        margin: 0 auto;
        .productMix {
            .title {
                padding: 40px 0 35px 0;
                font-size: 24px;
                font-weight: bold;
                color: #FF7100;
            }
            .product {
                .product_one {
                    margin: 20px 0;
                    span {
                        display: inline-block;
                        padding: 10px 24px;
                        background-color: #F3F1F1;
                        margin-right: 16px;
                    }
                    img {
                        margin-right: 16px;
                    }
                    .product_one_1 {
                        background-color: #F54405;
                        color: #fff;
                    }
                }
            }
            .images {
                text-align: center;
                margin-bottom: 50px;
             
            }
            .images2{
              margin-bottom: 50px;
              line-height: 45px;
              p{
                text-indent: 2em;
                font-size: 18px;
                color: #333;
              }
            }
        }
    }
</style>