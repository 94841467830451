<template>
  <div class="interact">
    <div class="contactDetails">
      <div class="title">投资者联系</div>
      <div class="details">
        <img src="../../assets/pic_zhengquan.png" alt="" />
        <div class="details_txt">
          <p>联系电话：0371-60317022</p>
          <p>邮 箱：cfo@zgw.com</p>
        </div>
      </div>
    </div>
    <div class="form">
      <div class="title">互动交流</div>
      <div class="el_form">
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="姓名 :" prop="name">
            <el-input v-model="ruleForm.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="电话 :" prop="mobile">
            <el-input v-model="ruleForm.mobile" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="邮箱 :" prop="email">
            <el-input
              type="Email"
              v-model="ruleForm.email"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="关键词 :" prop="keyWord">
            <el-input v-model="ruleForm.keyWord" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="提问内容 :" prop="askContent">
            <el-input
              type="textarea"
              v-model="ruleForm.askContent"
              autocomplete="off"
              :rows="7"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交问题</el-button
            >
          </el-form-item></el-form
        >
      </div>
    </div>
    <div class="message_con">
      <div class="message_tit">精华互动</div>
      <ul class="message_list">
        <li v-for="(item, index) in listDate" :key="index">
          <div class="message_que">
            <div class="key_words">
              <span class="key_font">关键词：</span>
              <span class="keywords_red">{{ item.keyWord }}</span>
            </div>
            <div class="que_con">
              <div class="que_con_txt">
                {{ item.askContent }}
              </div>
              <div class="que_time">{{ item.name }} {{ item.askDate }}</div>
            </div>
          </div>
          <div class="message_ans">
            <div class="ans_tit">证券部回复：</div>
            <div class="ans_con">
              {{ item.answerContent }}
            </div>
            <div class="ans_time">{{ item.answerDate }}</div>
          </div>
        </li>
      </ul>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="5"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { SummitQusertion } from "../../api/api";
import { GetInteractList } from "../../api/api";
export default {
  Name: "interact",
  data() {
    // 表单校验
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入您的姓名"));
      }
      if (value.length > 12) {
        callback(new Error("名字最多20个字"));
      } else {
        callback();
      }
    };
    var checkMobile = (rule, value, callback) => {
      const reg = /^1(3|4|5|6|7|8|9)\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    var checkEmail = (rule, value, callback) => {
      const reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
      if (value !== "") {
        if (!reg.test(value)) {
          callback(new Error("请输入正确的邮箱"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    var checkKeyWord = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入关键词"));
      }
      if (value.length > 12) {
        callback(new Error("最多20个字"));
      } else {
        callback();
      }
    };
    var checkAskContent = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入您的问题，最多200字"));
      }
      if (value.length > 120) {
        callback(new Error("最多200个字"));
      } else {
        callback();
      }
    };
    return {
      listDate: [],
      PageIndex: 1,
      PageCount: 5,
      currentPage4: 1,
      total: 20,
      ruleForm: {
        name: "",
        mobile: "",
        email: "",
        keyWord: "",
        askContent: ""
      },
      rules: {
        name: [{ validator: validatePass, trigger: "blur" }],
        mobile: [{ validator: checkMobile, trigger: "blur" }],
        keyWord: [{ validator: checkKeyWord, trigger: "blur" }],
        email: [{ validator: checkEmail, trigger: "blur" }],
        askContent: [{ validator: checkAskContent, trigger: "blur" }]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          let res = await SummitQusertion({
            name: this.ruleForm.name,
            mobile: this.ruleForm.mobile,
            email: this.ruleForm.email,
            keyWord: this.ruleForm.keyWord,
            askContent: this.ruleForm.askContent
          });
          console.log(
            "%c ✊: submitForm -> this.ruleForm ",
            "font-size:16px;background-color:#b0aba3;color:white;",
            this.ruleForm
          );
          if (res.statuscode == 200) {
            this.$alert("中钢网感谢您的支持和参与", "您的问题已提交成功", {
              confirmButtonText: "确定",
              callback: action => {
                this.ruleForm.name = "";
                this.ruleForm.mobile = "";
                this.ruleForm.email = "";
                this.ruleForm.keyWord = "";
                this.ruleForm.askContent = "";
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async GetList() {
      let res = await GetInteractList({
        PageIndex: this.PageIndex,
        PageCount: this.PageCount
      });
      if (res.statuscode == 200) {
        this.listDate = res.data;
        this.total = res.rowcount;
      }
    },
    handleSizeChange(val) {
      this.PageCount = val;
      console.log(1);
      this.GetList();
    },
    handleCurrentChange(val) {
      this.PageIndex = val;
      console.log(2);
      this.GetList();
    }
  },
  created() {
    this.GetList();
  }
};
</script>

<style lang="less" scoped>
.interact {
  border-top: 1px solid #eee;

  padding-top: 20px;
  .title {
    display: flex;
    justify-content: left;
    font-size: calc(100vw * 24 / 1920);
    font-weight: bold;
    color: #ff7100;
    line-height: 60px;
    padding: 0 18%;
    margin-bottom: 20px;
  }
  .details {
    width: 19.17vw;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0;
    img {
      width: 100%;
    }
    .details_txt {
      position: absolute;
      top: 41.4%;
      font-size: calc(100vw * 18 / 1920);
      :last-of-type {
        margin-top: 5%;
      }
    }
  }

  // 表单样式
  .form {
    .el-form {
      padding: 0 30%;
      ::v-deep.el-form-item__label {
        font-size: calc(100vw * 20 / 1920);
        color: #ff7100;
        font-weight: bolder;
        width: 140px !important;
      }
      ::v-deep.el-form-item__content {
        margin-left: 140px !important;
      }
      ::v-deep .el-button--primary {
        margin-top: 20px;
        background-color: #ff7100;
        border: none;
        border-radius: 0%;
        width: 7.81vw;
        color: #eee;
        font-weight: bold;
        font-size: calc(100vw * 18 / 1920);
        margin-bottom: 30px;
      }
    }
  }

  // 精华互动样式
  .message_con {
    // 精华互动部分全部隐藏
    display: none;
  }
  .message_tit {
    display: flex;

    justify-content: left;
    font-size: calc(100vw * 24 / 1920);
    font-weight: bold;
    color: #ff7100;
    line-height: 60px;
    padding: 0 18%;
  }
  .message_list {
    padding: 0 18%;
    list-style: none;

    li {
      padding: 30px 0;
      border-bottom: 1px solid #dddddd;
    }
    .key_words {
      .key_font {
        color: #666666;
        font-size: calc(100vw * 16 / 1920);
      }
      .keywords_red {
        background-color: #ff7100;
        padding: 5px 6px;
        color: #eee;
        font-size: calc(100vw * 16 / 1920);
      }
    }
    .que_con {
      margin: 18px 0 10px 0;
      font-size: calc(100vw * 16 / 1920);
      display: flex;
      justify-content: space-between;
      // line-height: 25px;
      .que_time {
        margin-right: 30px;
        color: #666666;
        font-size: calc(100vw * 16 / 1920);
      }
    }

    .message_ans {
      background-color: #f7f7f7;
      border: 1px #dddddd dashed;
      margin-top: 20px;
      padding: 30px;
      font-size: calc(100vw * 16 / 1920);

      .ans_tit {
        color: #ff7100;
      }
      .ans_con {
        color: #666666;
        margin: 20px 0;
      }
      .ans_time {
        color: #666666;
        display: flex;
        justify-content: right;
      }
    }
  }
  // 分页器样式
  .block {
    display: flex;
    justify-content: center;
    margin: 80px 0 40px 0;
    ::v-deep.el-pager li {
      width: calc(100vw * 32 / 1920);
    }
    ::v-deep .active {
      background-color: #ff7100;
      color: #eee;
      border-radius: 3px;
    }
  }
}
</style>