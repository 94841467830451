import { render, staticRenderFns } from "./pageBottom.vue?vue&type=template&id=07131b97&scoped=true&"
import script from "./pageBottom.vue?vue&type=script&lang=js&"
export * from "./pageBottom.vue?vue&type=script&lang=js&"
import style0 from "./pageBottom.vue?vue&type=style&index=0&id=07131b97&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07131b97",
  null
  
)

export default component.exports