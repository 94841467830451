<template>
  <div class="headBar">
    <img @click="skipZgw" src="../assets/pic_logo1.png" alt="">
  </div>
</template>

<script>
export default {
  name:'headBar',
  data(){
    return {}
  },
  methods:{
    skipZgw(){
      location.href="https://zgw.com"
    }
  }
}
</script>
<style lang="less" scoped>
  .headBar {
    width: 100%;
    height: calc(100vw*102/1920);
    background-color: #fff;
    img {
      margin-left: 19.5%;
      margin-top: calc(100vw*19/1920);
      width: calc(100vw*459/1920);
      cursor: pointer;
    }
  }
</style>