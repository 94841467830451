<template>
  <div id="app">
    <div class="fixedNavEmpty"></div>
    <div class="fixedNav">
      <headNav />
      <navBar />
    </div>
    <router-view/>
    <pageBottom />
  </div>
</template>

<script>
import headNav from "./component/headBar.vue";
import navBar from "./component/navBar.vue";
import pageBottom from "./component/pageBottom.vue";
export default {
  name: 'App',
  components: {
    headNav,
    navBar,
    pageBottom
  },
}
</script>

<style>
  * {
    margin: 0;
    padding: 0;
  }
  html{
    overflow-y:scroll
  }
  #app {
    position: relative;
  }
  .fixedNavEmpty {
    width: 100%;
    height: calc(100vw*182/1920);
  }
  .fixedNav {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
  }
</style>
