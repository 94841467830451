<template>
    <div class="announcement">
        <div class="subject">
            <div class="title">
                公司公告
            </div>
            <div class="list">
                <div class="listItem" v-for="(item,index) in newsList" :key="index" @click="skipInfos(item.id)">
                    <div class="listItem_title">{{item.titel}}</div>
                    <div class="listItem_time">{{item.createTimeStr}}</div>
                </div>
            </div>
            <div class="pageTab">
                <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[5, 10, 20, 30, 40]"
                :page-size="10"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { NewsList } from "../../api/api";
export default {
    name:'announcement',
    data() {
        return {
            newsList:[],
            PageIndex:1,
            PageCount:10,
            currentPage: 1,
            total:0
        }
    },
    created(){
        this.getList()
    },
    methods:{
        handleSizeChange(val) {
            this.PageCount = val;
            this.getList()
        },
        handleCurrentChange(val) {
            this.PageIndex = val;
            this.getList()
        },
        async getList(){
            const loading = this.$loading({         // 声明一个loading对象       
                lock: true,                           // 是否锁屏
                fullScreen: false,                     //是否为全屏 Dialog
                background: "rgba(255,255,255,0.7)"   //遮罩背景色
            })
            let res = await NewsList({
                TypeId:0,
                PageIndex:this.PageIndex,
                PageCount:this.PageCount
            });
            console.log("%c 🌲: created -> res ", "font-size:16px;background-color:#437693;color:white;", res)
            if(res.statuscode == 200){
                this.newsList = res.data
                this.total = res.rowcount
            }
            loading.close()
        },
        skipInfos(id){
            // this.$router.push({
            //     path:'/newsInfo',
            //     query:{id}
            // })
            let editUrl = this.$router.resolve({ 
                name: 'newsInfo', 
                query: {
                    id:id,
                    type:'gonggao'
                }
            })
            window.open(editUrl.href, '_blank')
        }
    }
}
</script>

<style lang="less" scoped>
    .announcement {
        .subject {
            width: 52%;
            margin: 0 auto;
            .title {
                padding: calc(100vw*40/1920) 0;
                height: calc(100vw*31/1920);
                font-size: calc(100vw*24/1920);
                font-weight: bold;
                color: #FF7100;
                line-height: calc(100vw*31/1920);
            }
            .list {
                width: 100%;
                margin-bottom: calc(100vw*110/1920);
                .listItem {
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    padding: calc(100vw*20/1920) 0;
                    .listItem_title {
                        font-size: calc(100vw*18/1920);
                        font-family: MicrosoftYaHei;
                        color: #333333;
                    }
                    .listItem_time {
                        font-size: calc(100vw*14/1920);
                        color: #999999;
                    }
                }
            }
            .pageTab {
                text-align: center;
                margin-bottom: calc(100vw*50/1920);
            }
        }
        
    }
</style>