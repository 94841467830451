<template>
  <div class="pageBottom">
    <div class="content">
      <div class="content-top">
        <div class="top_left">
          <div class="top_left_item">
            <div class="top_left_title">公司简介</div>
            <div class="top_left_txt">
              <p @click="skip($event)">公司简介</p>
            </div>
          </div>
          <div class="top_left_item">
            <div class="top_left_title">关于我们</div>
            <div class="top_left_txt">
              <p @click="skip($event)">企业文化</p>
              <p @click="skip($event)">成长历程</p>
              <p @click="skip($event)">董事长简介</p>
              <p @click="skip($event)">产品生态</p>
            </div>
          </div>
          <div class="top_left_item">
            <div class="top_left_title">新闻动态</div>
            <div class="top_left_txt">
              <p @click="skip($event)">公司动态</p>
              <p @click="skip($event)">团队风采</p>
            </div>
          </div>
          <div class="top_left_item">
            <div class="top_left_title">投资者关系</div>
            <div class="top_left_txt">
              <p @click="skip($event)">公司公告</p>
              <p @click="skip($event)">互动交流</p>
            </div>
          </div>
          <div class="top_left_item">
            <div class="top_left_title">中钢网公益</div>
            <div class="top_left_txt">
              <p @click="skip($event)">公益项目</p>
            </div>
          </div>
          <div class="top_left_item">
            <div class="top_left_title">联系我们</div>
            <div class="top_left_txt">
              <p @click="skip($event)">办公地点</p>
              <p @click="skip($event)">合作联系</p>
            </div>
          </div>
        </div>
        <div class="top_right">
          <div><img src="../assets/logo_wodeLogo.png" alt="" /></div>
          <div class="right_erweima">
            <div>
              <img src="../assets/logo_zgwHangqing.png" alt="" />
              <p>中钢网公众号</p>
            </div>
            <div>
              <img src="../assets/logo_zgwApp.png" alt="" />
              <p>中钢网App</p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-bottom">
        Copyright © 2022 中钢网All Rights Reserved
        全国咨询/投诉电话：400-700-8508 13676997586 15560247707 E-mail： wang@zgw.com
        <span @click="skipICP()">豫ICP备2021034665号-4</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "pageBottom",
  data() {
    return {};
  },
  methods: {
    skipICP() {
      window.open(
        "https://beian.miit.gov.cn/#/Integrated/recordQuery",
        "_blank"
      );
    },
    skip(e) {
      console.log(e.currentTarget.innerText);
      switch (e.currentTarget.innerText) {
        case "公司简介":
          this.$router.push({ name: "home" });
          break;
        case "企业文化":
          this.$router.push({ name: "companyCulture" });
          break;
        case "成长历程":
          this.$router.push({ path: "/growUprocess/licheng21" });
          break;
        case "董事长简介":
          this.$router.push({ name: "chairman" });
          break;
        case "产品生态":
          this.$router.push({ name: "businessOfScope" });
          break;
        case "公司动态":
          this.$router.push({ name: "newsDynamics" });
          break;
        case "团队风采":
          this.$router.push({ name: "teamStyle" });
          break;
        case "投资者联系":
          this.$router.push({ name: "cooperation" });
          break;
        case "公司公告":
          this.$router.push({ name: "announcement" });
          break;
        case "互动交流":
          this.$router.push({ name: "interact" });
          break;
        case "公益项目":
          this.$router.push({ name: "charity" });
          break;
        case "办公地点":
          this.$router.push({ name: "office" });
          break;
        case "合作联系":
          this.$router.push({ name: "cooperation" });
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.pageBottom {
  width: 100%;
  background-color: #1b1b1b;
  display: flex;
  justify-content: center;
  padding: 1.8% 0 2.3% 0;
  .content {
    width: 60%;
    .content-top {
      width: 100%;
      display: flex;
      justify-content: center;
      .top_left {
        width: 60%;
        border-right: 1px solid #888;
        margin: calc(100vw * 20 / 1920) 0;
        display: flex;
        justify-content: space-around;
        .top_left_item {
          margin-top: calc(100vw * 20 / 1920);
          width: 20%;
          text-align: center;
          .top_left_title {
            font-size: calc(100vw * 16 / 1920);
            color: #ffffff;
            margin-bottom: calc(100vw * 24 / 1920);
          }
          .top_left_txt {
            font-size: calc(100vw * 14 / 1920);
            color: #bbbbbb;
            p {
              margin-bottom: calc(100vw * 5 / 1920);
              cursor: pointer;
            }
            p:hover {
              color: #ff7100;
              text-decoration: underline;
            }
          }
        }
      }
      .top_right {
        flex: 1;
        margin: calc(100vw * 20 / 1920) 0;
        padding-left: calc(100vw * 75 / 1920);
        .right_erweima {
          display: flex;
          margin-top: calc(100vw * 10 / 1920);
          > div {
            font-size: calc(100vw * 14 / 1920);
            text-align: center;
            margin-right: calc(100vw * 30 / 1920);
            color: #bbbbbb;
          }
        }
      }
    }
    .content-bottom {
      width: 100%;
      padding: calc(100vw * 18 / 1920) 0;
      border-top: 1px solid #888;
      font-size: calc(100vw * 14 / 1920);
      color: #bbbbbb;
      text-align: center;
      span {
        cursor: pointer;
      }
      span:hover {
        color: #ff7100;
        text-decoration: underline;
      }
    }
  }
}
</style>