<template>
  <div class="newsInfo">
    <div class="headimg" v-if="newsType == 0">
      <img src="../../assets/pic_xinwen.png" alt="" />
    </div>
    <div class="headimg" v-if="newsType == 2">
      <img src="../../assets/pic_gonggao.png" alt="" />
    </div>
    <div class="public" v-if="newsType == 1">
      <h3>中钢网公益</h3>
      <p>公益详情</p>
    </div>
    <div class="public" v-else-if="newsType == 0">
      <h3>新闻动态</h3>
      <p>新闻详情</p>
    </div>
    <div class="info_body">
      <div class="info_title">{{ newInfo.allTitel }}</div>
      <div class="info_time">发布时间：{{ newInfo.createTime }}</div>
      <div class="info_content" v-html="newInfo.content">
        {{ newInfo.content }}
      </div>
    </div>
  </div>
</template>

<script>
import { NewsDetail } from "../../api/api";
export default {
  name: "newsInfo",
  data() {
    return {
      newInfo: {},
      newsType: 0
    };
  },
  created() {
    let id = this.$route.query.id;
    this.getNewsInfo(id);
  },
  methods: {
    async getNewsInfo(id) {
      const loading = this.$loading({
        // 声明一个loading对象
        lock: true, // 是否锁屏
        fullScreen: false, //是否为全屏 Dialog
        background: "rgba(255,255,255,0.7)" //遮罩背景色
      });
      if (this.$route.query.type && this.$route.query.type == "public") {
        this.newsType = 1;
      } else if (
        this.$route.query.type &&
        this.$route.query.type == "gonggao"
      ) {
        this.newsType = 2;
      } else if (
        this.$route.query.type &&
        this.$route.query.type == "charity"
      ) {
        this.newsType = 1;
      } else {
        this.newsType = 0;
      }
      let res = await NewsDetail({
        ID: id,
        Type: this.newsType
      });
      if (res.statuscode == 200) {
        console.log(
          "%c 🌙: getNewsInfo -> res ",
          "font-size:16px;background-color:#28b9d2;color:white;",
          res
        );
        this.newInfo = res.data;
      }
      loading.close();
    }
  }
};
</script>

<style lang="less" scoped>
.newsInfo {
  .headimg {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .public {
    width: 52%;
    margin: 0 auto;
    h3 {
      text-align: left;
      font-size: 24px;
      color: #fd5d23;
      font-weight: bold;
      clear: both;
      margin-top: 50px;
    }
    p {
      height: 30px;
      line-height: 30px;
      border-bottom: 1px solid #cccccc;
      font-size: 14px;
      color: #666666;
      margin-bottom: 30px;
      margin-top: 20px;
    }
  }
  .info_body {
    width: 52%;
    margin: 0 auto;
    overflow: auto;
    padding-bottom: calc(100vw * 100 / 1920);
    .info_title {
      // width: 100%;
      text-align: center;
      padding-top: calc(100vw * 24 / 1920);
      font-size: calc(100vw * 24 / 1920);
      font-weight: bold;
      color: #000000;
    }
    .info_time {
      width: 90%;
      margin: 0 auto;
      padding: calc(100vw * 8 / 1920) 0 calc(100vw * 14 / 1920) 0;
      border-bottom: 1px dashed #ddd;
      font-size: calc(100vw * 12 / 1920);
      color: #000000;
    }
    .info_content {
      padding-top: calc(100vw * 25 / 1920);
      font-size: calc(100vw * 14 / 1920);
      color: #333333;
    }
  }
}
</style>