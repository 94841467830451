import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/index.vue"
import interact from "../views/interact/index.vue"
import businessOfScope from "../views/businessOfScope/index.vue"
import newsDynamics from "../views/newsDynamics/index.vue"
import teamStyle from "../views/teamStyle/index.vue"
import announcement from "../views/announcement/index.vue"
import newsInfo from "../views/newsInfo/index.vue"
import growUprocess from "../views/growUprocess/licheng21.vue"


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: '公司简介'
    }
  },
  {
    // 业务范畴
    path: '/businessOfScope',
    name: 'businessOfScope',
    component: businessOfScope,
    meta: {
      title: '产品生态'
    }
  },
  {
    // 新闻动态
    path: '/newsDynamics',
    name: 'newsDynamics',
    component: newsDynamics,
    meta: {
      title: '公司动态'
    }
  },
  {
    // 团队风采
    path: '/teamStyle',
    name: 'teamStyle',
    component: teamStyle,
    meta: {
      title: '团队风采'
    }
  },
  {
    // 公司公告
    path: '/announcement',
    name: 'announcement',
    component: announcement,
    meta: {
      title: '公司公告'
    }
  },
  {
    // 新闻详情
    path: '/newsInfo',
    name: 'newsInfo',
    component: newsInfo,
    meta: {
      title: '新闻详情'
    }
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    component: () => import('../views/connect/cooperation.vue'),
    meta: {
      title: '合作联系'
    }
  },
  {
    path: '/office',
    name: 'office',
    component: () => import('../views/connect/office.vue'),
    meta: {
      title: '办公地点'
    }
  },
  {
    path: '/charity',
    name: 'charity',
    component: () => import('../views/charity/index.vue'),
    meta: {
      title: '中钢网公益'
    }
  },
  // {
  //   path: '/leader',
  //   name: 'leader',
  //   component: () => import('../views/aboutUs/leaderTeam.vue'),
  //   meta: {
  //     title: '领导团队'
  //   }
  // },
  {
    path: '/chairman',
    name: 'chairman',
    component: () => import('../views/chairman/index.vue'),
    meta: {
      title: '董事长简介'
    }
  },
  {
    path: '/companyCulture',
    name: 'companyCulture',
    component: () => import('../views/aboutUs/companyCulture.vue'),
    meta: {
      title: '企业文化'
    }
  },
  {
    path: '/interact',
    name: 'interact',
    component: () => import('../views/interact/index.vue'),
    meta: {
      title: '互动交流'
    }
  },
  {
    path: '/growUprocess/licheng23',
    name: 'growUprocess',
    component: () => import('../views/growUprocess/licheng23.vue'),
    meta: {
      title: '成长历程'
    }
  },
  {
    path: '/growUprocess/licheng22',
    name: 'growUprocess',
    component: () => import('../views/growUprocess/licheng22.vue'),
    meta: {
      title: '成长历程'
    }
  },
  {
    path: '/growUprocess/licheng21',
    name: 'growUprocess',
    component: growUprocess,
    meta: {
      title: '成长历程'
    }
  },
  {
    path: '/growUprocess/licheng20',
    name: 'growUprocess',
    component: () => import('../views/growUprocess/licheng20.vue'),
    meta: {
      title: '成长历程'
    }
  },
  {
    path: '/growUprocess/licheng19',
    name: 'growUprocess',
    component: () => import('../views/growUprocess/licheng19.vue'),
    meta: {
      title: '成长历程'
    }
  },
  {
    path: '/growUprocess/licheng18',
    name: 'growUprocess',
    component: () => import('../views/growUprocess/licheng18.vue'),
    meta: {
      title: '成长历程'
    }
  },
  {
    path: '/growUprocess/licheng17',
    name: 'growUprocess',
    component: () => import('../views/growUprocess/licheng17.vue'),
    meta: {
      title: '成长历程'
    }
  },
  {
    path: '/growUprocess/licheng16',
    name: 'growUprocess',
    component: () => import('../views/growUprocess/licheng16.vue'),
    meta: {
      title: '成长历程'
    }
  },
  {
    path: '/growUprocess/licheng15',
    name: 'growUprocess',
    component: () => import('../views/growUprocess/licheng15.vue'),
    meta: {
      title: '成长历程'
    }
  },
  {
    path: '/growUprocess/licheng14',
    name: 'growUprocess',
    component: () => import('../views/growUprocess/licheng14.vue'),
    meta: {
      title: '成长历程'
    }
  },
  {
    path: '/growUprocess/licheng13',
    name: 'growUprocess',
    component: () => import('../views/growUprocess/licheng13.vue'),
    meta: {
      title: '成长历程'
    }
  },
  {
    path: '/growUprocess/licheng12',
    name: 'growUprocess',
    component: () => import('../views/growUprocess/licheng12.vue'),
    meta: {
      title: '成长历程'
    }
  },
  {
    path: '/growUprocess/licheng11',
    name: 'growUprocess',
    component: () => import('../views/growUprocess/licheng11.vue'),
    meta: {
      title: '成长历程'
    }
  },
  {
    path: '/growUprocess/licheng10',
    name: 'growUprocess',
    component: () => import('../views/growUprocess/licheng10.vue'),
    meta: {
      title: '成长历程'
    }
  },
  {
    path: '/growUprocess/licheng09',
    name: 'growUprocess',
    component: () => import('../views/growUprocess/licheng09.vue'),
    meta: {
      title: '成长历程'
    }
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;