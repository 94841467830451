<template>
  <div class="teamStyle">
    <div class="headimg">
      <img src="../../assets/pic_xinwen.png" alt="" />
    </div>
    <div class="newsList">
      <div
        class="newsList_item"
        v-for="(item, index) in newsList"
        :key="index"
        @click="skipInfos(item)"
      >
        <div class="item_img"><img :src="item.newImg" alt="" /></div>
        <div class="item_info">
          <p class="item_info_title">{{ item.titel }}</p>
          <p class="item_info_txt">{{ item.allSummary }}...[详情]</p>
        </div>
        <div class="item_time">
          <p class="item_time_top">{{ item.createTimeStr | isDays }}</p>
          <p class="item_time_bottom">{{ item.createTimeStr | isYears }}</p>
        </div>
      </div>
    </div>
    <div class="pageTab">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { NewsList } from "../../api/api";
export default {
  name: "teamStyle",
  filters: {
    isDays(val) {
      if (val) {
        return val.split("/").slice(2).join("");
      } else {
        return "暂无";
      }
    },
    isYears(val) {
      if (val) {
        return val.split("/").slice(0, 2).join("-");
      } else {
        return "暂无";
      }
    }
  },
  data() {
    return {
      newsList: [],
      PageIndex: 1,
      PageCount: 10,
      currentPage: 1,
      total: 0
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleSizeChange(val) {
      this.PageCount = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.PageIndex = val;
      this.getList();
    },
    async getList() {
      // const loading = this.$loading({
      //   // 声明一个loading对象
      //   lock: true, // 是否锁屏
      //   fullScreen: false, //是否为全屏 Dialog
      //   background: "rgba(255,255,255,0.7)" //遮罩背景色
      // });
      let res = await NewsList({
        TypeId: 4,
        PageIndex: this.PageIndex,
        PageCount: this.PageCount
      });
      if (res.statuscode == 200) {
        console.log(
          "%c 👨‍👨‍👧‍👦: getList -> res ",
          "font-size:16px;background-color:#c95f19;color:white;",
          res
        );
        this.newsList = res.data;
        this.total = res.rowcount;
      }
      loading.close();
    },
    skipInfos(item) {
      console.log(
        "%c 📮: skipInfos -> item ",
        "font-size:16px;background-color:#4c1ca8;color:white;",
        item.id
      );
      // this.$router.push({
      //     path:'/newsInfo',
      //     query:{
      //         id:item.id
      //     }
      // })
      let editUrl = this.$router.resolve({
        name: "newsInfo",
        query: { id: item.id }
      });
      window.open(editUrl.href, "_blank");
    }
  }
};
</script>

<style lang="less" scoped>
.teamStyle {
  .headimg {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .newsList {
    margin: 0 auto;
    width: 58%;
    padding: calc(100vw * 40 / 1920) 0;
    > div:last-child {
      border: none;
    }
    .newsList_item {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      margin-top: calc(100vw * 20 / 1920);
      padding-bottom: calc(100vw * 10 / 1920);
      border-bottom: 1px solid #ddd;
      .item_img {
        width: 26.5%;
        height: calc(100vw * 181 / 1920);
        background-color: #ccc;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item_info {
        width: 60%;
        height: calc(100vw * 181 / 1920);
        padding-left: calc(100vw * 30 / 1920);
        .item_info_title {
          margin-top: calc(100vw * 30 / 1920);
          font-size: calc(100vw * 20 / 1920);
          font-weight: bold;
          color: #333333;
        }
        .item_info_txt {
          margin-top: calc(100vw * 17 / 1920);
          font-size: calc(100vw * 16 / 1920);
          color: #666666;
        }
      }
      .item_time {
        flex: 1;
        text-align: center;
        margin-top: calc(100vw * 55 / 1920);
        color: #666666;
        .item_time_top {
          font-size: calc(100vw * 32 / 1920);
        }
        .item_time_bottom {
          font-size: calc(100vw * 16 / 1920);
        }
      }
    }
  }
  .pageTab {
    text-align: center;
    margin-bottom: calc(100vw * 50 / 1920);
  }
}
</style>