<template>
  <div class="swiper">
    <el-carousel trigger="click" :height="SwiperHeight + 'px'">
      <el-carousel-item v-for="(item, index) in dataList" :key="index">
        <img :src="item.slideImg" alt="" @click="skipInfo(item.id)" />
        <div class="swiper_item_title">{{ item.titel }}</div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "swiper",
  props: {
    dataList: Array,
    SwiperHeight: [Number, String]
  },
  data() {
    return {};
  },
  methods: {
    skipInfo(id) {
      if (!id) return;
      if (this.$route.path == "/charity") {
        // this.$router.push({
        //   name:"newsInfo",
        //   query:{
        //     type:'public',
        //     id:id
        //   }
        // })
        let editUrl = this.$router.resolve({
          name: "newsInfo",
          query: { type: "charity", id: id }
        });
        window.open(editUrl.href, "_blank");
      } else {
        // this.$router.push({
        //   name:"newsInfo",
        //   query:{id}
        // })
        let editUrl = this.$router.resolve({ name: "newsInfo", query: { id } });
        window.open(editUrl.href, "_blank");
      }
    }
  }
};
</script>
<style lang="less" scoped>
.swiper {
  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .swiper_item_title {
    width: 100%;
    height: 70px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, #000000 100%);
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 0 20px;
    line-height: 70px;
    color: #fff;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>