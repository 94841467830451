<template>
  <div class="navBar">
    <div
      class="nav_item"
      v-for="(item, index) in listData"
      :key="index"
      v-on:mouseover="showTag(index)"
      v-on:mouseout="handleHide"
    >
      <span
        :class="isShow == index ? 'nav_item_title' : ''"
        @click="skipUrl(item.path)"
        >{{ item.title }}</span
      >
      <span class="item_line" v-if="listData.length != index + 1">|</span>
      <div
        class="hover_div"
        v-if="item.children && item.children.length > 0"
        v-show="index == isShow"
      >
        <div
          v-for="(ite, ind) in item.children"
          :key="ind"
          :class="erIsShow == ind ? 'erTitleHover' : 'erTitle'"
          v-on:mouseover="showTages(ind)"
          v-on:mouseout="handleHides"
          @click="skipUrl(ite.path)"
        >
          {{ ite.ertitle }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "navBar",
  data() {
    return {
      listData: [
        {
          title: "公司简介",
          path: "/"
        },
        {
          title: "关于我们",
          children: [
            { ertitle: "企业文化", path: "/companyCulture" },
            { ertitle: "成长历程", path: "/growUprocess/licheng23" },
            { ertitle: "董事长简介", path: "/chairman" },
            { ertitle: "产品生态", path: "/businessOfScope" }
          ]
        },
        {
          title: "新闻动态",
          children: [
            { ertitle: "公司动态", path: "/newsDynamics" },
            { ertitle: "团队风采", path: "/teamStyle" }
          ]
        },
        {
          title: "投资者关系",
          children: [
            { ertitle: "公司公告", path: "/announcement" },
            { ertitle: "互动交流", path: "/interact" }
          ]
        },
        {
          title: "中钢网公益",
          path: "/charity"
        },
        // {
        //   title: "诚聘英才",
        //   path: "/recruit"
        // },
        {
          title: "联系我们",
          children: [
            { ertitle: "办公地点", path: "/office" },
            { ertitle: "合作联系", path: "/cooperation" }
          ]
        }
      ],
      isShow: -1,
      erIsShow: -1
    };
  },
  methods: {
    showTag(index) {
      this.isShow = index;
    },
    handleHide() {
      this.isShow = -1;
    },
    showTages(index) {
      this.erIsShow = index;
    },
    handleHides() {
      this.erIsShow = -1;
    },
    skipUrl(path) {
      // 诚聘英才跳转到外部链接
      if(path == '/recruit'){
        window.open('https://zgwrencai.my.qingzhan.com/', '_blank')
      }else{
        this.$router.push(path);
      }
    }
  }
};
</script>
<style lang="less" scoped>
.navBar {
  width: 100%;
  height: calc(100vw * 80 / 1920);
  padding: 0 16%;
  box-sizing: border-box;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  .nav_item {
    // width: 16.6%;
    height: calc(100vw * 26 / 1920);
    line-height: calc(100vw * 26 / 1920);
    font-size: calc(100vw * 20 / 1920);
    color: #666;
    cursor: pointer;
    padding: calc(100vw * 27 / 1920) 0 calc(100vw * 27 / 1920)
      calc(100vw * 20 / 1920);
    text-align: center;
    position: relative;
    z-index: 10001;
    .item_line {
      margin-left: calc(100vw * 60 / 1920);
      color: #ddd;
    }
    .nav_item_title {
      padding-bottom: calc(100vw * 15 / 1920);
      font-weight: bold;
      color: #ff7100;
      border-bottom: 3px solid #ff7100;
    }
    .hover_div {
      position: absolute;
      top: calc(100vw * 78 / 1920);
      left: -10px;
      width: calc(100vw * 150 / 1920);
      border: 1px solid #f2f2f2;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
      z-index: 99;
      > div {
        font-size: calc(100vw * 18 / 1920);
        width: 100%;
        height: calc(100vw * 45 / 1920);
        line-height: calc(100vw * 45 / 1920);
      }
      .erTitle {
        background-color: #fff;
        line-height: calc(100vw * 45 / 1920);
      }
      .erTitleHover {
        color: #fff;
        background-color: #ff7100;
      }
    }
  }
}
</style>