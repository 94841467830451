import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import _ from "lodash";
import ElementUI from 'element-ui';
import 'animate.css';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false
Vue.prototype._ = _;
Vue.use(ElementUI);

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (!to.meta.haveLogin) {
    next();
  }
  // } else {
  //   if (
  //     !store.getters.getUserInfo ||
  //     store.getters.getUserInfo == null ||
  //     store.getters.getUserInfo.length == 0
  //   ) {
  //     if (to.name == "Cgdetail") {
  //       store.commit("changLoginReturn", to.fullPath);
  //       if (!isPhone()) phoneToPc(to);
  //     }
  //     next({
  //       path: "/login",
  //     });
  //   } else {
  //     next();
  //   }
  // }
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

