import { get, post } from "./http";

// 测试环境
// export const apiUrl = "https://appv2servicet.zgw.com/api";
// export const Url = "https://appv2servicet.zgw.com/api/Official";
// export const href = "http://mtest.zgw.com";
// export const weUrl = 'https://cardsteelt.zgw.com'

export const apiUrl = "https://appv2services.zgw.com/api";
export const Url = "https://appv2services.zgw.com/api/Official";
export const href = "http://m.zgw.com";
export const weUrl = 'https://cardsteels.zgw.com'



// 获取首页新闻列表内容
export const IndexNews = (params) => {
  return get(Url + "/IndexNews", params);
};

// 获取首页新闻列表内容
export const NewsList = (params) => {
  return get(Url + "/NewsList", params);
};

// 获取首页新闻列表内容
export const NewsDetail = (params) => {
  return get(Url + "/NewsDetail", params);
};

// 获取钢市月刊
export const GetSteelMonthlYear = (params) => {
  return post(apiUrl + "/Master/GetSteelMonthlyByYear", params);
};
// 获取公益页面轮播内容
export const GetCharityCon = () => {
  return get(Url + '/PublicMindedNewsList')
}

// 提交
export const SummitQusertion = (params) => {
  return post(Url + '/SummitQusertion', params)
}

//获取互动交流页面精华互动内容
export const GetInteractList = (params) => {
  return get(Url + '/InteractList', params)
}

