import md5 from "md5";
const _ = require("lodash");

export const getToken = () => {
    let Time = new Date().getTime();
    let From = 1;
    let FromPass = "PC";
    let StringT = "pc(%+(-A/#*-C^%!.$)";
    let RequestNo = _.random(5000);
    return {
      Time,
      From,
      RequestNo,
      Token: md5(
        String(Time) + String(FromPass) + String(RequestNo) + String(StringT)
      ),
    };
  };