<template>
  <div class="home">
    <div class="home_bg1"><img src="../../assets/beijing1.png" alt="" /></div>
    <div class="home_bg2"><img src="../../assets/beijing22.jpg" alt="" /></div>
    <div class="home_bg3"><img src="../../assets/beijing3.png" alt="" /></div>
    <div class="home_news">
      <div class="home_news_top">
        <div class="news_top_title">新闻动态</div>
        <div
          class="news_top_more"
          style="cursor: pointer"
          @click="skipmoreNews"
        >
          <span>查看更多</span>
          <img
            style="
              vertical-align: -4px;
              margin-left: calc(100vw * 5 / 1920);
              width: calc(100vw * 16 / 1920);
            "
            src="../../assets/btn_you4.png"
            alt=""
          />
        </div>
      </div>
      <div class="home_news_bottom">
        <div class="news_swiper" ref="newSwiper">
          <swiper :dataList="newslist" :SwiperHeight="SwiperH" />
        </div>
        <div class="news_small">
          <div
            class="small_news_item white"
            style="cursor: pointer"
            @click="skipInfo(twoNewsOne.id)"
          >
            <img class="posi_imgs" :src="twoNewsOne.slideImg" alt="" />
            <div class="posi_text">{{ twoNewsOne.titel }}</div>
          </div>

          <div
            class="small_news_item white"
            @click="skipInfo(twoNewsTwo.id)"
            style="cursor: pointer"
          >
            <!-- <img class="posi_img" :src="twoNewsTwo.slideImg" alt=""> -->
            <div class="posi_txt">
              <div class="two_title">{{ twoNewsTwo.titel }}</div>
              <div class="two_content">
                {{ twoNewsTwo.summary }}
              </div>
              <div class="twonews_line greys"></div>
              <div class="twonews_time">
                <div>
                  <p class="black">{{ twoNewsTwo.createTimeStr | isTime }}</p>
                  <p class="blacky">{{ twoNewsTwo.createTimeStr | isYear }}</p>
                </div>
                <div><img src="../../assets/btn_you3.png" alt="" /></div>
              </div>
            </div>
          </div>
        </div>
        <div class="news_detail">
          <div class="news_detail_img">
            <img v-if="oneNews" :src="oneNews.slideImg" alt="" />
          </div>
          <div class="news_detail_info">
            <p @click="skipInfo(oneNews.id)">{{ oneNews.titel }}</p>
            <div class="news_detail_info_txt">
              <p>{{ oneNews.summary }}</p>
            </div>
            <span
              style="font-size: calc(100vw * 14 / 1920); cursor: pointer"
              @click="skipInfo(oneNews.id)"
              v-if="oneNews"
              >[阅读全文]</span
            >
          </div>
          <div class="news_detail_line"></div>
          <div class="news_detail_list">
            <div
              class="news_detail_listitem"
              v-for="(item, index) in newTitleList"
              :key="index"
              @click="skipInfo(item.id)"
            >
              <div class="listitem_title">
                <div><img src="../../assets/icon_sanjiao.png" alt="" /></div>
                <div
                  style="
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: calc(100vw * 230 / 1920);
                  "
                >
                  {{ item.titel }}
                </div>
              </div>
              <div class="listitem_time">{{ item.createTimeStr }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="environment">
      <div class="environment_title">企业环境</div>
      <div class="environment_img">
        <div class="swiper_left">
          <div class="environment_swiper" ref="envSwiper">
            <Swiper :dataList="list" :SwiperHeight="envSwiper" />
          </div>
          <div class="swiper_bottom">
            <div class="swiper_right_item">
              <img src="../../assets/pic_huodong2.png" alt="" />
            </div>
            <div class="swiper_right_item">
              <img src="../../assets/pic_huiyiting.png" alt="" />
            </div>
          </div>
        </div>
        <div class="swiper_right">
          <div class="swiper_right_item">
            <img src="../../assets/pic_bangong.png" alt="" />
          </div>
          <div class="swiper_right_item">
            <img src="../../assets/pic_huiyishi.png" alt="" />
          </div>
          <div class="swiper_right_item">
            <img src="../../assets/pic_huiyishi2.png" alt="" />
          </div>
          <div class="swiper_right_item">
            <img src="../../assets/pic_huodong1.png" alt="" />
          </div>
          <div class="swiper_right_item">
            <img src="../../assets/pic_qixie.png" alt="" />
          </div>
          <div class="swiper_right_item">
            <img src="../../assets/pic_bangongshi.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { IndexNews } from "../../api/api";
import Swiper from "../../component/swiper.vue";
export default {
  name: "home",
  components: {
    Swiper
  },
  filters: {
    isTime(val) {
      return val.split("/").slice(1, 3).join(".");
    },
    isYear(val) {
      return val.split("/").slice(0, 1).join("");
    }
  },
  data() {
    return {
      newslist: [],
      list: [
        {
          titel: "中钢网运营中心",
          slideImg: require("../../assets/home_swiper2.jpg")
        },
        {
          titel: "中钢网活动中心",
          slideImg: require("../../assets/home_swiper1.jpg")
        },
        {
          titel: "中钢网会议厅",
          slideImg: require("../../assets/home_swiper3.jpg")
        }
      ],
      SwiperH: "",
      envSwiper: "",
      oneNews: {},
      twoNewsOne: {},
      twoNewsTwo: {},
      newTitleList: []
    };
  },
  async created() {
    let res = await IndexNews({});
    console.log(
      "%c 🚏: created -> res ",
      "font-size:16px;background-color:#f2ae1d;color:white;",
      res
    );
    if (res.statuscode == 200) {
      this.newslist = res.data.slideNewsList;

      let recommendArray = res.data.recommendNewsList;
      if (recommendArray && recommendArray.length > 0) {
        this.oneNews = recommendArray[2];
        this.twoNewsOne = recommendArray[0];
        this.twoNewsTwo = recommendArray[1];
      } else {
        return;
      }
      if (res.data.otherNewsList && res.data.otherNewsList.length > 0) {
        this.newTitleList = res.data.otherNewsList;
      } else {
        return;
      }
    }
  },
  updated() {
    this.SwiperH = this.$refs.newSwiper.offsetHeight;
    this.envSwiper = this.$refs.envSwiper.offsetHeight;
    window.onresize = () => {
      this.$nextTick(() => {
        this.SwiperH = this.$refs.newSwiper.offsetHeight;
        this.envSwiper = this.$refs.envSwiper.offsetHeight;
      });
    };
  },
  methods: {
    skipInfo(id) {
      console.log(
        "%c 🇮🇱: skipInfo -> id ",
        "font-size:16px;background-color:#93e277;color:black;",
        id
      );
      // this.$router.push({
      //   path: "/newsInfo",
      //   query: { id }
      // });
      let editUrl = this.$router.resolve({
        path: "/newsInfo",
        query: { id: id }
      });
      window.open(editUrl.href, "_blank");
    },
    skipmoreNews() {
      let editUrl = this.$router.resolve({
        path: "/newsDynamics"
      });
      window.open(editUrl.href, "_blank");
    }
  }
};
</script>
<style lang="less" scoped>
.home {
  .home_bg1,
  .home_bg2,
  .home_bg3 {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .home_bg1 {
    height: calc(100vw * 686.86 / 1920);
  }
  .home_bg2 {
    height: calc(100vw * 560 / 1920);
  }
  .home_news {
    padding-top: 0;
    padding-left: calc(100vw * 219 / 1920);
    padding-right: calc(100vw * 219 / 1920);
    padding-bottom: calc(100vw * 56 / 1920);
    background: url("../../assets/bg4.png");
    .home_news_top {
      width: 100%;
      padding-top: calc(100vw * 35 / 1920);
      padding-bottom: calc(100vw * 35 / 1920);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .news_top_title {
        font-size: calc(100vw * 52 / 1920);
        font-weight: bold;
        color: #ff7100;
      }
      .news_top_more {
        font-size: calc(100vw * 18 / 1920);
        color: #ff7100;
        font-weight: 500;
      }
    }
    .home_news_bottom {
      display: flex;
      justify-content: space-between;
      .news_swiper {
        width: 49.05%;
        height: calc(100vw * 548 / 1920);
      }
      .news_small {
        width: 23.85%;
        height: calc(100vw * 548 / 1920);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .white {
          background-color: #fff;
        }
        .small_news_item {
          width: 100%;
          height: calc(100vw * 264 / 1920);
          position: relative;
          .posi_text {
            margin-top: 10px;
            text-align: center;
            color: #ff7100;
            font-weight: bold;
            font-size: calc(100vw * 16 / 1920);
          }
          .posi_imgs {
            width: 100%;
            height: 76%;
          }
          .posi_img {
            width: 100%;
            height: 100%;
          }
          .posi_txt {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            padding-top: calc(100vw * 15 / 1920);
            .whites {
              color: #fff !important;
            }
            .two_title {
              font-size: calc(100vw * 16 / 1920);
              font-weight: bold;
              color: #ff7100;
              padding: calc(100vw * 16 / 1920) 0 calc(100vw * 16 / 1920)
                calc(100vw * 16 / 1920);
            }
            .two_content {
              margin-left: calc(100vw * 16 / 1920);
              height: calc(100vw * 100 / 1920);
              font-size: calc(100vw * 14 / 1920);
            }
            .twonews_line {
              width: 90%;
              margin: 0 auto;
              height: 1px;
              margin: 0 auto;
              background: #fff;
              opacity: 0.78;
            }
            .greys {
              background: #ddd !important;
            }
            .twonews_time {
              display: flex;
              justify-content: space-between;
              padding: calc(100vw * 30 / 1920) calc(100vw * 20 / 1920)
                calc(100vw * 20 / 1920) calc(100vw * 20 / 1920);
              .black {
                font-size: calc(100vw * 20 / 1920);
                color: #333;
              }
              .fontwhite {
                font-size: calc(100vw * 20 / 1920);
                color: #fff;
              }
              .blacky {
                font-size: calc(100vw * 14 / 1920);
                color: #333;
              }
              .fontwhitey {
                font-size: calc(100vw * 14 / 1920);
                color: #fff;
              }
            }
          }
        }
      }
      .news_detail {
        width: 23.85%;
        height: calc(100vw * 548 / 1920);
        background-color: #fff;
        .news_detail_img {
          height: calc(100vw * 200 / 1920);
          img {
            width: 100%;
            height: 100%;
          }
        }
        .news_detail_info {
          padding: calc(100vw * 14 / 1920) calc(100vw * 6 / 1920);
          .news_detail_info_txt {
            width: 100%;
            height: calc(100vw * 80 / 1920);
            box-sizing: border-box;
            padding: calc(100vw * 12 / 1920) 0 calc(100vw * 4 / 1920)
              calc(100vw * 10 / 1920);
            > p {
              font-size: calc(100vw * 14 / 1920);
              color: #666;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
          > p {
            cursor: pointer;
            color: #ff7100;
            margin-left: calc(100vw * 10 / 1920);
            font-size: calc(100vw * 16 / 1920);
            font-weight: bold;
          }
          span {
            color: #ff7100;
            margin-left: calc(100vw * 10 / 1920);
            display: inline-block;
            text-decoration: none;
          }
        }
        .news_detail_line {
          width: 90%;
          margin: 0 auto;
          height: 1px;
          background: #dddddd;
        }
        .news_detail_list {
          padding: calc(100vw * 10 / 1920);
          .news_detail_listitem {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            margin-top: calc(100vw * 10 / 1920);
            .listitem_title {
              display: flex;
              align-items: center;
              font-size: calc(100vw * 14 / 1920);
              img {
                width: 100%;
                vertical-align: -4px;
              }
            }
            .listitem_time {
              font-size: calc(100vw * 14 / 1920);
              color: #999999;
              line-height: calc(100vw * 24 / 1920);
            }
          }
        }
      }
    }
  }
  .environment {
    width: 100%;
    height: calc(100vw * 981 / 1920);
    padding-bottom: calc(100vw * 50 / 1920);
    background: url("../../assets/bg5.png");
    .environment_title {
      padding: calc(100vw * 30 / 1920) 0;
      text-align: center;
      font-size: calc(100vw * 48 / 1920);
      font-weight: bold;
      color: #ff7100;
    }
    .environment_img {
      width: 100%;
      height: calc(100vw * 600 / 1920);
      box-sizing: border-box;
      padding: calc(100vw * 35 / 1920) calc(100vw * 105 / 1920);
      display: flex;
      justify-content: space-between;
      margin-bottom: calc(100vw * 20 / 1920);
      .swiper_left {
        width: 49%;
        .environment_swiper {
          width: 100%;
          height: calc(100vw * 532 / 1920);
        }
        .swiper_bottom {
          width: 100%;
          margin-top: calc(100vw * 10 / 1920);
          display: flex;
          justify-content: space-between;
          .swiper_right_item {
            width: 49%;
            height: calc(100vw * 260 / 1920);
            background-color: #ccc;
            margin-bottom: calc(100vw * 10 / 1920);
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .swiper_right {
        width: 50%;
        height: calc(100vw * 532 / 1920);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .swiper_right_item {
          width: 49%;
          height: calc(100vw * 260 / 1920);
          background-color: #ccc;
          margin-bottom: calc(100vw * 10 / 1920);
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>