/* 引入animated.css动画库 <h1 class="animate__animated animate__fadeIn">An animated element</h1>  */
<template>
    <div class="licheng21">
        <div class="licheng_body">
            <div class="timeTree">
                <div class="time_line">
                    <div class="animate__animated animate__fadeInLeft"></div>
                </div>
                <div class="years">
                    <div class="years_item animate__animated animate__fadeIn">
                        <p @click="skipyears">2022</p>
                        <div class="yuandian"></div>
                    </div>
                    <div class="years_item animate__animated animate__fadeIn" style="transform: scale(1.2);margin-top:-5px">
                        <p @click="skipyears">2021</p>
                        <div class="yuandian" style="background-color: #FF7100;"></div>
                    </div>
                    <div class="years_item animate__animated animate__fadeIn">
                        <p @click="skipyears">2020</p>
                        <div class="yuandian"></div>
                    </div>
                    <div class="years_item animate__animated animate__fadeIn">
                        <p @click="skipyears">2019</p>
                        <div class="yuandian"></div>
                    </div>
                    <div class="years_item animate__animated animate__fadeIn">
                        <p @click="skipyears">2018</p>
                        <div class="yuandian"></div>
                    </div>
                </div>
                <div class="youjiantou" @click="prevRight"><img src="../../assets/history_next.png" alt=""></div>
                <div class="zuojiantou" @click="prevLeft"><img src="../../assets/history_prev.png" alt=""></div>
            </div>

            <div class="photoTree">
                <div class="licheng_title"><img src="../../assets/pic_licheng.png" alt=""></div>
                <div class="outerBox">
                    <!-- 第一大块 -->
                    <!-- 右边整体的内容 -->
                    <div class="dateLeftBox" style="top: 200px;">
                        <h2>5月22日<br>中钢网董事长姚红超一行赴天明集团参观学习。</h2>
                        <div>
                            <img src="../../assets/pic_tianming.png" alt="">
                        </div>
                    </div>
                    <!-- 第二大块 -->
                    <!-- 左边整体的内容 -->
                    <div class="dateBox" style="top: 400px;">
                        <h2>5月6日<br>中钢网“追梦赤子心、奋斗最青春”2021年十大杰出青年表彰大会隆重举行。</h2>
                        <div>
                            <img src="../../assets/licheng21-2.png" alt="">
                        </div>
                    </div>
                    <!-- 右边整体的内容 -->
                    <div class="dateLeftBox" style="top: 600px;">
                        <h2>2月20日<br>中钢网《双周精英集训营》正式开课。</h2>
                        <div>
                            <img src="../../assets/licheng21-3.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"licheng21",
    data() {
        return {

        }
    },
    methods:{
        skipyears(e){
            let year = e.target.innerText.slice(2)
            this.$router.push('/growUprocess/licheng'+year)
        },
        prevRight(){
            let year = this.$route.path.split('/').slice(2).join('').slice(-2)
            year = Number(year)-1
            if(year<10){
                year = '0'+String(year)
            }
            this.$router.push('/growUprocess/licheng'+year)
        },
        prevLeft(){
            let year = this.$route.path.split('/').slice(2).join('').slice(-2)
            year = Number(year)+1
            this.$router.push('/growUprocess/licheng'+year)
        },
    }
}
</script>

<style lang="less" scoped>
    .licheng21 {
        .licheng_body {
            width: 74%;
            // border: 1px solid red;
            margin: 0 auto;
            position: relative;
            .timeTree {
                width: 100%;
                background-color: #F4F6E7;
                overflow: hidden;
                padding: 40px 0 60px 0;
                .years {
                    display: flex;
                    justify-content: center;
                    margin-top: -63px;
                    .years_item {
                        width: 15%;
                        text-align: center;
                        animation-duration: 3s;
                        p {
                            cursor: pointer;
                            font-size: 28px;
                            font-weight: bold;
                            color: #FF7100;
                            
                        }
                        p:hover {
                            transform: scale(1.1);
                        }
                        .yuandian {
                            width: 22px;
                            height: 22px;
                            background: #E6D176;
                            border-radius: 50%;
                            margin: 14px auto 0;
                        }
                    }
                }
                .time_line {
                    width: 75%;
                    height: 2px;
                    margin: 80px auto 0;
                    overflow: hidden;
                    >div {
                        width: 100%;
                        height: 2px;
                        background:linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, #000000 100%);
                        background: #E6D176;
                        animation-duration: 1.5s
                    }
                }
                .youjiantou {
                    position: absolute;
                    right: 8%;
                    top: 81px;
                    cursor: pointer;
                    img:hover {
                        transform: scale(1.2);
                    }
                } 
                .zuojiantou {
                    position: absolute;
                    left: 8%;
                    top: 81px;
                    cursor: pointer;
                    img:hover {
                        transform: scale(1.2);
                    }
                } 
            }
            .photoTree {
                width: 100%;
                margin-top: 65px;
                .licheng_title {
                    text-align: center;
                }
            }
            
        }
        
    }
</style>
<style lang="css" scoped>
    .outerBox {
        /* 竖线样式 高度根据事件的多少调整*/
        width: 5px;
        height: 800px;
        background: #F54405;
        margin: 0 auto 40px;
        position: relative;
        -webkit-animation: heightSlide 0.5s linear;
        z-index: -1;
    }

    @-webkit-keyframes heightSlide {
        /* 竖线的动画效果：以百分比来规定改变发生的时间，0% 是动画的开始时间，100% 动画的结束时间 */
        0% {
            height: 400px;
        }
        100% {
            height: 800px;
        }
    }
    .outerBox:after {
        /* 竖线末尾文字样式 */
        content: '';
        width: 100px;
        color: rgb(84, 84, 85);
        position: absolute;
        margin-left: -47px;
        text-align: center;
        bottom: -30px;
        -webkit-animation: showIn 5.5s ease;
    }
    .outerBox .dateBox,
    .outerBox .dateLeftBox {
        /* 球球的样式 */
        position: absolute;
        margin-left: -8px;
        margin-top: -10px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 4px solid #E6D176;
        background: #E6D176;
        -webkit-transition: all 0.5s;
        -webkit-animation: showIn ease;
    }
    .outerBox .dateBox:nth-child(1) {
        /* 第一个事件 设置动画在几秒内完成 */
        -webkit-animation-duration: 1s;
    }
    .outerBox .dateLeftBox:nth-child(2) {
        /* 第二个事件 设置动画在几秒内完成 */
        -webkit-animation-duration: 1.5s;
    }

    .outerBox .dateBox:nth-child(3) {
        /* 第三个事件 设置动画在几秒内完成 */
        -webkit-animation-duration: 2s;
    }

    .outerBox .dateLeftBox:nth-child(4) {
        /* 第四个事件 设置动画在几秒内完成 */
        -webkit-animation-duration: 2.5s;
    }

    .outerBox .dateBox:nth-child(5) {
        /* 第五个事件 设置动画在几秒内完成 */
        -webkit-animation-duration: 3s;
    }

    .outerBox .dateLeftBox:nth-child(6) {
        /* 第六个事件 设置动画在几秒内完成 */
        -webkit-animation-duration: 3.5s;
    }

    .outerBox .dateBox:nth-child(7) {
        /* 第七个事件 设置动画在几秒内完成 */
        -webkit-animation-duration: 4s;
    }

    .outerBox .dateLeftBox:nth-child(8) {
        /* 第八个事件 设置动画在几秒内完成 */
        -webkit-animation-duration: 4.5s;
    }

    @-webkit-keyframes showIn {
        /* 球球、竖线、左右的模块的动画 */
        0% {
            opacity: 0.;
        }
        50% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
        }
    }
    .outerBox .dateBox h2,
    .outerBox .dateLeftBox h2 {
        /* 日期的样式 */
        position: absolute;
        margin-left: -630px;
        width: 600px;
        margin-top: -30px;
        text-align: right;
        color: rgb(84, 84, 85);
        font-size: 20px;
        cursor: pointer;
        /* -webkit-animation: showIn 3s ease; */
    }
    .outerBox .dateLeftBox h2 {
        /* 右边日期的样式 */
        text-align: left;
        margin-left: 40px;
        width: 500px;
    }

    .outerBox .dateBox div,
    .outerBox .dateLeftBox div {
        /* 左右事件的样式 */
        position: absolute;
        top: -100px;
        margin-top: -15px;
        left: 50px;
        width: 392px;
        height: 242px;
        /* height: 21px; */
        /* border: 2px solid rgb(84, 84, 85); */
        z-index: 2;
        overflow: hidden;
        cursor: pointer;
        -webkit-animation: showIn 1s ease;
        -webkit-transition: all 0.5s;
    }
    .outerBox .dateBox div img,
    .outerBox .dateLeftBox div img {
        width: 100%;
        height: 100%;
    }
    .outerBox .dateLeftBox div {
        /* 左边事件的样式 */
        left: -420px;
    }

</style>