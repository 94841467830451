import axios from "axios";
import {
  getToken
} from "../util/util";
import { Message } from "element-ui";
// import store from "@/store/index";

axios.create({
  timeout: 20000, //超时时间'
});
axios.interceptors.request.use(
  (config) => {
    // const token = store.state.token;
    // token && (config.headers.Authorization = token);
    let data = getToken();
    config.headers.Token = data.Token;
    config.headers.Time = data.Time;
    config.headers.From = data.From;
    config.headers.RequestNo = data.RequestNo;
   
    if (localStorage.getItem("userinfo")) {
      config.headers.memberId = JSON.parse(localStorage.getItem("userinfo")).memberID
    }else{
      config.headers.memberId = ''
    }
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },

  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
        //   router.replace({
        //     path: "/login",
        //     query: {
        //       redirect: router.currentRoute.fullPath,
        //     },
        //   });
          break;

          // 403 token过期
          // 登录过期对用户进行提示
          // 清除本地token和清空vuex中token对象
          // 跳转登录页面
        case 403:
          Message({
            message: "登录过期，请重新登录",
            duration: 1000,
            forbidClick: true,
          });
          // 清除token
          localStorage.removeItem("token");
        //   store.commit("loginSuccess", null);
          // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
        //   setTimeout(() => {
        //     router.replace({
        //       path: "/login",
        //       query: {
        //         redirect: router.currentRoute.fullPath,
        //       },
        //     });
        //   }, 1000);
          break;

          // 404请求不存在
        case 404:
          Message({
            message: "网络请求不存在",
            duration: 1500,
            // forbidClick: true,
          });
          break;
          // 其他错误，直接抛出错误提示
        default:
          this.$message({
            message: error.response.data.message,
            duration: 1500,
            // forbidClick: true,
          });
      }
      return Promise.reject(error.response);
    }
  }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}